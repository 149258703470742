<template>
  <a-row class="ministry">
    <a-row class="content-title" type="flex">
      <h2 class="flex-1">
        {{ $route.meta.title[$i18n.locale] }}
      </h2>

      <a-row
        class="flex-no__wrap ml-auto wanted-search-input-wrapper"
        type="flex"
        style="justify-content: flex-end"
      />
    </a-row>

    <a-table
      :key="$route.fullPath"
      :columns="columns"
      :data-source="tableProps"
    >
      <template slot="name" slot-scope="item">
        <div
          style="cursor: pointer"
          @click="
            $router.push({
              name: item.name,
              params: {
                id: item.id,
                type: item.type
              }
            })
          "
        >
          {{ item.title[$i18n.locale] }}
        </div>
      </template>
    </a-table>
  </a-row>
</template>

<script>
const columns = [
  {
    title: "Название",
    key: "name",
    class: "wanted-fio-td",
    scopedSlots: {
      customRender: "name"
    }
  }
]

export default {
  data: () => ({
    tableProps: [
      {
        title: {
          oz: "НПА Марказий апарат",
          uz: "NPA Markaziy aparyat",
          ru: "Центральный аппарат НПА",
          en: "Headquarters laws"
        },
        type: "central_hardware",
        name: "laws"
      },
      {
        title: {
          oz: "НПА вазирлиги тасарруфидаги ташкилотлар",
          uz: "NPA vazirligi tasarrufidagi tashkilotlar",
          ru: "Подведомственные организации НПА",
          en: "Subordinated organizations laws"
        },
        type: "ministry_organization",
        name: "laws"
      },
      {
        title: {
          oz: "Кучини йукотган хужжатлар",
          uz: "Kuchini yo'qotgan hujjatlar",
          ru: "Не действующие НПА",
          en: "Kyh"
        },
        name: "kyh"
      },
      {
        title: {
          oz: "МХХ шакли",
          uz: "MHH shakli",
          ru: "Форма НПА",
          en: "MHH shakli"
        },
        name: "mhh"
      },
      {
        title: {
          oz: "Орган",
          uz: "Organ",
          ru: "Орган",
          en: "Agency"
        },
        name: "agency"
      }
    ],
    columns
  }),
  methods: {}
}
</script>
<style>
.wanted-search-input-wrapper {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 430px;
  flex: 0 0 430px;
  justify-content: space-around;
}
/*.ministry .ant-table-pagination.ant-pagination {
  display: none;
}*/
</style>
